import React,{useEffect,useState}  from "react";


import "./ApiDataList.css";



const HookTest = (props) => {

    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [items, setItems] = useState([]);
    const usermessage = props.userMessage;
    const getSelectedOption = props.actionProvider.getSelectedOption('selectedOptions');

    // Note: the empty deps array [] means
    // this useEffect will run once
    // similar to componentDidMount()
    useEffect( () => {
        var endpoint = window.encodeURI(`https://aipl-staging.uhn.ca/ai/getresponse/`);
        
        if(props.environmentName=='production'){
             endpoint = window.encodeURI(`https://aipl.uhn.ca/ai/getresponse/`);
        }
        const axios = require('axios').default;
        axios.post(endpoint, {
            message: usermessage,
            selectedOption: getSelectedOption
        })
            .then(function (response) {
                setIsLoaded(true);
                setItems(response.data);
                console.log(response.data);
            })
            .catch(function (error) {
                console.log(error.toJSON());
            });
    }, []);
    const linkMarkup =
        <span>
            <div dangerouslySetInnerHTML={{__html: (items.response_text)}}/>
        </span>




    ;



    return <div className="react-chatbot-kit-chat-bot-message" style={{ background: "#2A6EB5"}}>{linkMarkup}</div>;
}
export default HookTest;
